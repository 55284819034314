import { isNumber, isString, isBoolean } from 'lodash';

export const lazyDefault = {
  loadPrevNext: true,
  loadPrevNextAmount: 1,
  loadOnTransitionStart: false,
  elementClass: 'swiper-lazy',
  loadingClass: 'swiper-lazy-loading',
  loadedClass: 'swiper-lazy-loaded',
  preloaderClass: 'swiper-lazy-preloader',
};

export function lazyValidator(value) {
  if (value.loadPrevNext && !isBoolean(value.loadPrevNext)) return false;
  if (value.loadPrevNextAmount && !isNumber(value.loadPrevNextAmount)) return false;
  if (value.loadOnTransitionStart && !isBoolean(value.loadOnTransitionStart)) return false;
  if (value.elementClass && !isString(value.elementClass)) return false;
  if (value.loadingClass && !isString(value.loadingClass)) return false;
  if (value.loadedClass && !isString(value.loadedClass)) return false;
  if (value.preloaderClass && !isString(value.preloaderClass)) return false;
  return true;
}
