import { isBoolean, isNumber, isArray } from 'lodash';

export const virtualDefault = {
  slides: [],
  cache: true,
  renderSlide: null,
  renderExternal: null,
  renderExternalUpdate: true,
  addSlidesBefore: 0,
  addSlidesAfter: 0,
};

export function virtualValidator(value) {
  if (value.slides && !isArray(value.slides)) return false;
  if (value.cache && !isBoolean(value.cache)) return false;
  if (value.renderSlide && !(value.renderSlide instanceof Function)) return false;
  if (value.renderExternal && !(value.renderExternal instanceof Function)) return false;
  if (value.renderExternalUpdate && !isBoolean(value.renderExternalUpdate)) return false;
  if (value.addSlidesBefore && !isNumber(value.addSlidesBefore)) return false;
  if (value.addSlidesAfter && !isNumber(value.addSlidesAfter)) return false;
  return true;
}
