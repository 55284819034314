import { isBoolean } from 'lodash';

export const keyboardDefault = {
  enabled: false,
  onlyInViewport: true,
  pageUpDown: true,
};

export function keyboardValidator(value) {
  if (value.enabled && !isBoolean(value.enabled)) return false;
  if (value.onlyInViewport && !isBoolean(value.onlyInViewport)) return false;
  if (value.pageUpDown && !isBoolean(value.pageUpDown)) return false;
  return true;
}
