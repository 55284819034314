import { isString, isBoolean } from 'lodash';

export const navigationDefault = {
  nextEl: null,
  prevEl: null,
  hideOnClick: false,
  disabledClass: 'swiper-button-disabled',
  hiddenClass: 'swiper-button-hidden',
  lockClass: 'swiper-button-lock',
};

export function navigationValidator(value) {
  if (value.nextEl && !(isString(value.nextEl) || value.nextEl instanceof HTMLElement))
    return false;
  if (value.prevEl && !(isString(value.prevEl) || value.prevEl instanceof HTMLElement))
    return false;
  if (value.hideOnClick && !isBoolean(value.hideOnClick)) return false;
  if (value.disabledClass && !isString(value.disabledClass)) return false;
  if (value.hiddenClass && !isString(value.hiddenClass)) return false;
  if (value.lockClass && !isString(value.lockClass)) return false;
  return true;
}
