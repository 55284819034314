export default [
  'init',
  'beforeDestroy',
  'destroy',
  'slideChange',
  'slideChangeTransitionStart',
  'slideChangeTransitionEnd',
  'slideNextTransitionStart',
  'slideNextTransitionEnd',
  'slidePrevTransitionStart',
  'slidePrevTransitionEnd',
  'transitionStart',
  'transitionEnd',
  'touchStart',
  'touchMove',
  'touchMoveOpposite',
  'sliderMove',
  'touchEnd',
  'click',
  'tap',
  'doubleTap',
  'imagesReady',
  'progress',
  'reachBeginning',
  'reachEnd',
  'toEdge',
  'fromEdge',
  'setTranslate',
  'setTransition',
  'resize',
  'observerUpdate',
  'beforeLoopFix',
  'loopFix',
  'breakpoint',
  'autoplayStart',
  'autoplayStop',
  'autoplay',
  'hashChange',
  'hashSet',
  'lazyImageLoad',
  'lazyImageReady',
  'navigationHide',
  'navigationShow',
  'paginationRender',
  'paginationUpdate',
  'paginationHide',
  'paginationShow',
  'scrollbarDragStart',
  'scrollbarDragMove',
  'scrollbarDragEnd',
  'zoomChange',
];
