<template>
  <main-card class="pricelist-card" :class="classes" @click.native="$emit('click')">
    <div class="pricelist__title medium-title">
      {{ title.replace(/SSL Certificate -/gi, '') }}
    </div>
    <ul v-if="list.length" class="pricelist-card__list note-text">
      <li v-for="(item, i) in list" :key="i" class="pricelist-card__list-item">
        {{ item }}
      </li>
    </ul>
    <div v-if="$slots.default || desc" class="pricelist-card__desc note-text">
      <slot>{{ desc }}</slot>
    </div>
    <!--    <div v-if="discount" class="pricelist-card__discount error-color standart-title">-->
    <!--      <slot></slot>-->
    <!--    </div>-->
    <template v-if="!cloud && +discount > 0" #footer>
      <div class="pricelist-card__discount error-color standart-title">
        <!--        {{ discount }}-->
        {{ $n(discountToNumber, 'currency') + ' / ' + isYear }}
      </div>
    </template>
    <template v-if="!cloud" #footerEnd>
      <div class="pricelist-card__price standart-title">
        <!--        {{ cloud ? null : $n(priceToNumber, 'currency') + ' / ' + $t(`period.${periodToString}`) }}-->
        <!--        {{ cloud ? null : $n(priceToNumber, 'currency') + ' / ' + $t(`period.${periodToString}`) }}-->
        {{ cloud ? null : $n(priceToNumber, 'currency') + ' / ' + isYear }}
      </div>
    </template>
  </main-card>
</template>

<script>
import { isNaN } from 'lodash';
import { BillMgrPeriods } from '@/models/BillMgr/periods';
import MainCard from '@/components/MainCard/MainCard';
export default {
  name: 'PricelistCard',
  components: { MainCard },
  props: {
    title: {
      type: String,
      required: true,
    },

    list: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true,
    },
    price: {
      type: [Number, String],
      required: true,
      validator: val => !isNaN(parseFloat(val)),
    },
    period: {
      type: Number,
      default: 1,
      validator: val => Object.keys(BillMgrPeriods).includes(val.toString()),
    },
    active: {
      type: Boolean,
      default: false,
    },
    desc: {
      type: String,
      default: '',
    },
    cloud: {
      type: Boolean,
      default: false,
    },
    discount: {
      type: [Number, String],
      default: null,
      // required: true,
      validator: val => !isNaN(parseFloat(val)),
    },
  },
  computed: {
    classes() {
      const base = 'pricelist-card';
      return {
        [`${base}--active`]: this.active,
        [`${base}--disabled`]: this.$attrs.disabled,
      };
    },
    isYear() {
      return this.period === 12 ? 'год' : 'месяц';
    },
    periodToString() {
      return BillMgrPeriods[this.period];
    },
    priceToNumber() {
      return parseFloat(this.price);
    },
    discountToNumber() {
      return parseFloat(this.discount);
    },
  },
  mounted() {},
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.pricelist-card {
  cursor: pointer;

  &--active {
    background: $primary-color !important;
    color: $color-light.white !important;
    border-color: transparent !important;

    .note-text {
      color: $color-light.white;
    }
  }
  &--disabled,
  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
  }

  &__list {
    no-style-list();
  }

  &__list,
  &__desc {
    margin-top: 0.75rem;
    transition: color $anim-base-duration ease;
  }
  &__discount {
    //margin-top: 0.75rem;
    transition: color $anim-base-duration ease;
    text-decoration: line-through;

  }

  &__price {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

}
</style>
