import { isString, isNumber, isBoolean } from 'lodash';

export const paginationDefault = {
  el: '.swiper-pagination',
  type: 'bullets',
  bulletElement: 'span',
  dynamicBullets: false,
  dynamicMainBullets: 1,
  hideOnClick: false,
  clickable: false,
  progressbarOpposite: false,
  formatFractionCurrent: number => number,
  formatFractionTotal: number => number,
  renderBullet: null,
  renderFraction: null,
  renderProgressbar: null,
  renderCustom: null,
  bulletClass: 'swiper-pagination-bullet',
  bulletActiveClass: 'swiper-pagination-bullet-active',
  modifierClass: 'swiper-pagination-',
  currentClass: 'swiper-pagination-current',
  totalClass: 'swiper-pagination-total',
  hiddenClass: 'swiper-pagination-hidden',
  progressbarFillClass: 'swiper-pagination-progressbar-fill',
  clickableClass: 'swiper-pagination-clickable',
  lockClass: 'swiper-pagination-lock',
  progressbarOppositeClass: 'swiper-pagination-progressbar-opposite',
};

export function paginationValidator(value) {
  if (value.el && !(isString(value.el) || value.el instanceof HTMLElement)) return false;
  if (value.hideOnClick && !isBoolean(value.hideOnClick)) return false;
  if (value.clickable && !isBoolean(value.clickable)) return false;
  if (value.type && !['bullets', 'fraction', 'progressbar', 'custom'].includes(value.type))
    return false;
  if (value.type && value.type === 'bullet') {
    if (value.bulletElement && !isString(value.bulletElement)) return false;
    if (value.dynamicBullets && !isBoolean(value.dynamicBullets)) return false;
    if (value.dynamicMainBullets && !isNumber(value.dynamicMainBullets)) return false;
    if (value.renderBullet && !(value.renderBullet instanceof Function)) return false;
    if (value.bulletClass && !isString(value.bulletClass)) return false;
    if (value.bulletActiveClass && !isString(value.bulletActiveClass)) return false;
  }
  if (value.type && value.type === 'fraction') {
    if (value.formatFractionCurrent && !(value.formatFractionCurrent instanceof Function))
      return false;
    if (value.formatFractionTotal && !(value.formatFractionTotal instanceof Function)) return false;
    if (value.renderFraction && !(value.renderFraction instanceof Function)) return false;
    if (value.currentClass && !isString(value.currentClass)) return false;
    if (value.totalClass && !isString(value.totalClass)) return false;
  }
  if (value.type && value.type === 'progressbar') {
    if (value.progressbarOpposite && !isBoolean(value.progressbarOpposite)) return false;
    if (value.renderProgressbar && !(value.renderProgressbar instanceof Function)) return false;
    if (value.progressbarFillClass && !isString(value.progressbarFillClass)) return false;
    if (value.progressbarOppositeClass && !isString(value.progressbarOppositeClass)) return false;
  }
  if (value.type && value.type === 'custom') {
    if (!value.renderCustom || !(value.renderCustom instanceof Function)) return false;
  }
  if (value.modifierClass && !isString(value.modifierClass)) return false;
  if (value.hiddenClass && !isString(value.hiddenClass)) return false;
  if (value.clickableClass && !isString(value.clickableClass)) return false;
  if (value.lockClass && !isString(value.lockClass)) return false;
  return true;
}
