import { isString, isNumber, isBoolean } from 'lodash';

export const scrollbarDefault = {
  el: '.swiper-scrollbar',
  hide: true,
  draggable: false,
  snapOnRelease: true,
  dragSize: 'auto',
  lockClass: 'swiper-scrollbar-lock',
  dragClass: 'swiper-scrollbar-drag',
};

export function scrollbarValidator(value) {
  if (value.el && !(isString(value.el) || value.el instanceof HTMLElement)) return false;
  if (value.hide && !isBoolean(value.hide)) return false;
  if (value.draggable && !isBoolean(value.draggable)) return false;
  if (value.snapOnRelease && !isBoolean(value.snapOnRelease)) return false;
  if (value.dragSize && !(isNumber(value.dragSize) || value.dragSize === 'auto')) return false;
  if (value.lockClass && !isString(value.lockClass)) return false;
  if (value.dragClass && !isString(value.dragClass)) return false;
  return true;
}
