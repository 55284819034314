import { isBoolean, isString } from 'lodash';

export const a11yDefault = {
  enabled: true,
  prevSlideMessage: 'Previous slide',
  nextSlideMessage: 'Next slide',
  firstSlideMessage: 'This is the first slide',
  lastSlideMessage: 'This is the last slide',
  paginationBulletMessage: 'Go to slide {{index}}',
  notificationClass: 'swiper-notification',
};

export function a11yValidator(value) {
  if (value.enabled && !isBoolean(value.enabled)) return false;
  if (value.prevSlideMessage && !isString(value.prevSlideMessage)) return false;
  if (value.nextSlideMessage && !isString(value.nextSlideMessage)) return false;
  if (value.firstSlideMessage && !isString(value.firstSlideMessage)) return false;
  if (value.lastSlideMessage && !isString(value.lastSlideMessage)) return false;
  if (value.paginationBulletMessage && !isString(value.paginationBulletMessage)) return false;
  if (value.notificationClass && !isString(value.notificationClass)) return false;
  return true;
}
