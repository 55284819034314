<template>
  <div :id="`swiper-${id}`" :key="`swiper-${id}`" class="base-swiper" :class="classes">
    <div ref="container" class="base-swiper__container swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(item, i) in list"
          :key="i"
          class="swiper-slide"
          :style="{
            width: isAutoSlidesPerView
              ? `${autoSlideWidth}px`
              : instance
              ? `${instance.slidesSizesGrid[i]}px`
              : null,
          }"
        >
          <slot name="item" :item="item"></slot>
          <div v-if="lazy" class="swiper-lazy-preloader base-swiper__loader"></div>
        </div>
      </div>
      <div
        v-if="hasPaginationAtAll"
        v-show="hasPagination"
        ref="pagination"
        class="base-swiper__pagination swiper-pagination"
      ></div>

      <div
        v-if="hasScrollbarAtAll"
        v-show="hasScrollbar"
        ref="scrollbar"
        class="swiper-scrollbar"
      ></div>
    </div>

    <div
      v-if="hasNavigationAtAll"
      v-show="hasNavigation"
      class="base-swiper__navigation swiper-navigation"
    >
      <div ref="prev" class="base-swiper__prev swiper-button-prev">
        <slot name="prev">
          <base-button theme="plain" :color="color" :icon="prevIcon" />
        </slot>
      </div>
      <div ref="next" class="base-swiper__next swiper-button-next">
        <slot name="next">
          <base-button theme="plain" :color="color" :icon="nextIcon" />
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { BaseSwiperProps, SwiperComponentsList } from './BaseSwiperProps';
import BaseSwiperEvents from './BaseSwiperEvents';
import { camelCase, isBoolean } from 'lodash';
import { navigationDefault, navigationValidator } from './helpers/navigation';
import { paginationDefault, paginationValidator } from './helpers/pagination';
import { scrollbarDefault, scrollbarValidator } from './helpers/scrollbar';
import { autoplayDefault, autoplayValidator } from './helpers/autoplay';
import { lazyDefault, lazyValidator } from './helpers/lazy';
import { fadeEffectDefault, fadeEffectValidator } from './helpers/fadeEffect';
import { coverflowEffectDefault, coverflowEffectValidator } from './helpers/coverflowEffect';
import { flipEffectDefault, flipEffectValidator } from './helpers/flipEffect';
import { cubeEffectDefault, cubeEffectValidator } from './helpers/cubeEffect';
import { thumbsDefault, thumbsValidator } from './helpers/thumbs';
import { zoomDefault, zoomValidator } from './helpers/zoom';
import { keyboardDefault, keyboardValidator } from './helpers/keyboard';
import { mousewheelDefault, mousewheelValidator } from './helpers/mousewheel';
import { virtualDefault, virtualValidator } from './helpers/virtual';
import { hashNavigationDefault, hashNavigationValidator } from './helpers/hashNavigation';
import { historyDefault, historyValidator } from './helpers/history';
import { controllerDefault, controllerValidator } from './helpers/controller';
import { a11yDefault, a11yValidator } from './helpers/a11y';
export default {
  name: 'BaseSwiper',
  props: {
    // data
    list: {
      type: Array,
      default: () => [],
    },
    // base props
    ...BaseSwiperProps,
    // swiper components
    navigation: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || navigationValidator(value),
    },
    pagination: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || paginationValidator(value),
    },
    scrollbar: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || scrollbarValidator(value),
    },
    autoplay: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || autoplayValidator(value),
    },
    parallax: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: [Boolean, Object],
      default: false,
      validator: value => isBoolean(value) || lazyValidator(value),
    },
    fadeEffect: {
      type: [Boolean, Object],
      default: false,
      validator: value => isBoolean(value) || fadeEffectValidator(value),
    },
    coverflowEffect: {
      type: [Boolean, Object],
      default: false,
      validator: value => isBoolean(value) || coverflowEffectValidator(value),
    },
    flipEffect: {
      type: [Boolean, Object],
      default: false,
      validator: value => isBoolean(value) || flipEffectValidator(value),
    },
    cubeEffect: {
      type: [Boolean, Object],
      default: false,
      validator: value => isBoolean(value) || cubeEffectValidator(value),
    },
    thumbs: {
      type: Object,
      default: null,
      validator: value => value && thumbsValidator(value),
    },
    zoom: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || zoomValidator(value),
    },
    keyboard: {
      type: [Boolean, Object],
      default: false,
      validator: value => isBoolean(value) || keyboardValidator(value),
    },
    mousewheel: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || mousewheelValidator(value),
    },
    virtual: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || virtualValidator(value),
    },
    hashNavigation: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || hashNavigationValidator(value),
    },
    history: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || historyValidator(value),
    },
    controller: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || controllerValidator(value),
    },
    a11y: {
      type: [Object, Boolean],
      default: false,
      validator: value => isBoolean(value) || a11yValidator(value),
    },
    // vue component
    color: {
      type: String,
      default: 'dark',
      validator: value => ['dark', 'light'].includes(value),
    },
    autoSlideWidth: {
      type: Number,
      default: null,
    },
    prevIcon: {
      type: String,
      default: 'angle-left',
    },
    nextIcon: {
      type: String,
      default: 'angle-right',
    },
  },
  data() {
    return {
      prefix: 'base-swiper',
      instance: null,
      hasNavigationAtAll:
        this.navigation ||
        (this.breakpoints && Object.values(this.breakpoints).some(i => i.showNavigation)),
      hasPaginationAtAll:
        this.pagination ||
        (this.breakpoints && Object.values(this.breakpoints).some(i => i.pagination)),
      hasScrollbarAtAll:
        this.scrollbar ||
        (this.breakpoints && Object.values(this.breakpoints).some(i => i.scrollbar)),
      rafId: null,
      id: this._uid,
    };
  },
  computed: {
    isAutoSlidesPerView() {
      return (
        (this.slidesPerView === 'auto' && !this.breakpoints) ||
        (this.instance &&
          this.breakpoints &&
          this.breakpoints[this.instance.currentBreakpoint].slidesPerView &&
          this.breakpoints[this.instance.currentBreakpoint].slidesPerView === 'auto')
      );
    },
    classes() {
      return {
        [`${this.prefix}--has-navigation`]: this.hasNavigation,
        [`${this.prefix}--has-pagination`]: this.hasPagination,
        [`${this.prefix}--has-scrollbar`]: this.hasScrollbar,
        [`${this.prefix}--color_${this.color}`]: this.color,
        nested: this.nested,
        [this.noSwipingClass]: this.noSwiping,
      };
    },
    // components
    hasNavigation() {
      if (!this.navigation) return false;
      return (
        (this.navigation &&
          (!this.breakpoints ||
            !Object.values(this.breakpoints).some(v => v.showNavigation !== undefined))) ||
        (!!this.instance &&
          !!this.breakpoints &&
          !!this.breakpoints[this.instance.currentBreakpoint].showNavigation)
      );
    },
    navigationOptions() {
      if (!this.hasNavigationAtAll) return null;
      return this.navigation instanceof Object
        ? { ...navigationDefault, ...this.navigation }
        : navigationDefault;
    },
    hasPagination() {
      if (!this.pagination) return false;
      return (
        (this.pagination &&
          (!this.breakpoints ||
            !Object.values(this.breakpoints).some(v => v.showPagination !== undefined))) ||
        (!!this.instance &&
          !!this.breakpoints &&
          !!this.breakpoints[this.instance.currentBreakpoint].showPagination)
      );
    },
    paginationOptions() {
      if (!this.hasPaginationAtAll) return null;
      return this.pagination instanceof Object
        ? { ...paginationDefault, ...this.pagination }
        : paginationDefault;
    },
    hasScrollbar() {
      if (!this.scrollbar) return false;
      return (
        this.scrollbar ||
        (this.instance &&
          this.breakpoints &&
          this.breakpoints[this.instance.currentBreakpoint].scrollbar)
      );
    },
    scrollbarOptions() {
      if (!this.hasScrollbarAtAll) return null;
      return this.scrollbar instanceof Object
        ? { ...scrollbarDefault, ...this.scrollbar }
        : scrollbarDefault;
    },
    autoplayOptions() {
      if (!this.autoplay) return null;
      return this.autoplay instanceof Object
        ? { ...autoplayDefault, ...this.autoplay }
        : autoplayDefault;
    },
    lazyOptions() {
      if (!this.lazy) return null;
      return this.lazy instanceof Object ? { ...lazyDefault, ...this.lazy } : lazyDefault;
    },
    fadeEffectOptions() {
      if (!this.fadeEffect) return null;
      return this.fadeEffect instanceof Object
        ? { ...fadeEffectDefault, ...this.fadeEffect }
        : fadeEffectDefault;
    },
    coverflowEffectOptions() {
      if (!this.coverflowEffect) return null;
      return this.coverflowEffect instanceof Object
        ? { ...coverflowEffectDefault, ...this.coverflowEffect }
        : coverflowEffectDefault;
    },
    flipEffectOptions() {
      if (!this.flipEffect) return null;
      return this.flipEffect instanceof Object
        ? { ...flipEffectDefault, ...this.flipEffect }
        : flipEffectDefault;
    },
    cubeEffectOptions() {
      if (!this.cubeEffect) return null;
      return this.cubeEffect instanceof Object
        ? { ...cubeEffectDefault, ...this.cubeEffect }
        : cubeEffectDefault;
    },
    thumbsOptions() {
      if (!this.thumbs) return null;
      return { ...thumbsDefault, ...this.thumbs };
    },
    zoomOptions() {
      if (!this.zoom) return false;
      return this.zoom instanceof Object ? { ...zoomDefault, ...this.zoom } : zoomDefault;
    },
    keyboardOptions() {
      if (!this.keyboard) return false;
      return this.keyboard instanceof Object
        ? { ...keyboardDefault, ...this.keyboard }
        : keyboardDefault;
    },
    mousewheelOptions() {
      if (!this.mousewheel) return false;
      return this.mousewheel instanceof Object
        ? { ...mousewheelDefault, ...this.mousewheel }
        : mousewheelDefault;
    },
    virtualOptions() {
      if (!this.virtual) return false;
      return this.virtual instanceof Object
        ? { ...virtualDefault, ...this.virtual }
        : virtualDefault;
    },
    hashNavigationOptions() {
      if (!this.hashNavigation) return false;
      return this.hashNavigation instanceof Object
        ? { ...hashNavigationDefault, ...this.hashNavigation }
        : hashNavigationDefault;
    },
    historyOptions() {
      if (!this.history) return false;
      return this.history instanceof Object
        ? { ...historyDefault, ...this.history }
        : historyDefault;
    },
    controllerOptions() {
      if (!this.controller) return false;
      return this.controller instanceof Object
        ? { ...controllerDefault, ...this.controller }
        : controllerDefault;
    },
    a11yOptions() {
      if (!this.a11y) return false;
      return this.a11y instanceof Object ? { ...a11yDefault, ...this.a11y } : a11yDefault;
    },
    // swiper options
    swiperOptions() {
      const opts = {};
      for (const i in Object.keys(BaseSwiperProps)) {
        const prop = Object.keys(BaseSwiperProps)[i];
        opts[prop] = this[prop];
      }
      for (const i in Object.keys(SwiperComponentsList)) {
        const comp = Object.keys(SwiperComponentsList)[i];
        const compOptions = this[`${comp}Options`];
        if (compOptions) opts[comp] = compOptions;
      }
      return opts;
    },
  },
  watch: {
    slidesPerView: function (value) {
      this.updateSwiperParam('slidesPerView', value);
    },
    spaceBetween: function (value) {
      this.updateSwiperParam('spaceBetween', value);
    },
    breakpoints: {
      handler: function (value) {
        this.updateSwiperParam('breakpoints', value);
      },
      deep: true,
    },
  },
  beforeMount() {
    this.validateProps();
  },
  mounted() {
    setTimeout(this.initSwiper);
  },
  beforeDestroy() {
    if (this.instance) this.instance.destroy();
    if (this.rafId) cancelAnimationFrame(this.rafId);
  },
  methods: {
    validateProps() {
      if (this.fadeEffect || this.coverflowEffect || this.flipEffect || this.cubeEffect)
        this.validateEffect();
      this.validateGrid();
    },
    validateEffect() {
      for (const effectName in ['fade', 'coverflow', 'flip', 'cube']) {
        if (this[`${effectName}Effect`] && this.effect !== effectName) {
          console.warn(
            `Unavailable props combination: set 'effect' to '${effectName}' or remove '${effectName}Effect' property.`
          );
        }
      }
    },
    validateGrid() {
      if (this.slidesPerView === 'auto' && !this.autoSlideWidth) {
        console.warn(
          "You must specify autoSlideWidth (number, in px) if slidesPerView is setted to 'auto', or set slidesPerView to number"
        );
      }
    },
    getFinalOptions() {
      const finalOptions = { ...this.swiperOptions };
      if (this.hasNavigationAtAll && !finalOptions.navigation.prevEl)
        finalOptions.navigation.prevEl = this.$refs.prev;
      if (this.hasNavigationAtAll && !finalOptions.navigation.nextEl)
        finalOptions.navigation.nextEl = this.$refs.next;
      if (this.hasPaginationAtAll && !finalOptions.pagination.el)
        finalOptions.pagination.el = this.$refs.pagination;
      if (this.hasScrollbarAtAll && !finalOptions.scrollbar.el)
        finalOptions.scrollbar.el = this.$refs.scrollbar;
      if (!finalOptions.on.init && this.$listeners.init) {
        finalOptions.on.init = this.$listeners.init;
      }
      return finalOptions;
    },
    getModules(options) {
      const arr = [];
      const componentsKeys = Object.keys(SwiperComponentsList);
      for (const i in componentsKeys) {
        const prop = componentsKeys[i];
        if (options[prop]) arr.push(SwiperComponentsList[prop]);
      }
      return arr;
    },
    initSwiper() {
      const options = this.getFinalOptions();
      const modules = this.getModules(options);
      Swiper.use(modules);
      this.instance = new Swiper(this.$refs.container, options);
      this.emitEvents(this.instance);
      this.$emit('created', this.instance);
    },
    emitEvents(swiper) {
      Object.keys(this.$listeners).forEach(e => {
        if (BaseSwiperEvents.includes(camelCase(e))) {
          swiper.on(camelCase(e), this.$listeners[e]);
        }
      });
    },
    updateSwiperParam(param, value) {
      this.instance.params[param] = value;
      this.instance.update();
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$bs-button-size = 60px;
$bs-button-offset = ($bs-button-size + 10px);
$bs-pagination-size = 2px;
$bs-colors = {};
.base-swiper {
  margin-bottom: 3rem;
  position: relative;
  width: 100%;

  &__container {
    overflow: auto;
  }
  // navigation
  &--has-navigation {
    padding: 0 $bs-button-offset;
  }
  &__navigation {
    absolute(50px, 0px);
    size(100%, 0px);
    overflow: visible;
  }
  &__prev,
  &__next {
    size($bs-button-size);
    margin-top: -($bs-button-size / 2);
    &::after {
      display: none;
    }
  }
  &__prev {
    left: 0;
  }
  &__next {
    right: 0;
  }

  // lazy
  &__loader {
    color: inherit;
    border-color: currentColor;
    border-top-color: transparent;
  }
  >>> .swiper-lazy {
    opacity: 0;

    &-loaded {
      opacity: 1;
    }
  }

  // pagination
  &__pagination {
    top: 16px;
    left: 16px;
    right: 16px;
    bottom: auto
    width: auto;
    flexy(center, stretch);
    visibility: visible;

    >>> .swiper-pagination-bullet,
    >>> .swiper-pagination-bullet-progressive {
      display: block;
      flex: 1 1 auto;
      height: $bs-pagination-size;
      border-radius: 0;

      & + .swiper-pagination-bullet,
      & + .swiper-pagination-bullet-progressive {
        margin-left: $bs-pagination-size;
      }
    }
    >>> .swiper-pagnation-bullet {
      opacity: 0.2;
      transition: opacity 0.3s;

      &-active {
        opacity: 1;
      }

      for $key, $color in $bs-colors {
        ^[0]--color_{$key} & {
          background: $color.base;
        }
      }
    }

    >>> .swiper-pagination-bullet-progressive {
     opacity: 1;

      for $key, $color in $bs-colors {
        ^[0]--color_{$key} & {
          background: rgba($color.base, 0.2);

          &_inner {
            background: $color.base;
          }
       }
     }

      &_inner {
        size(100%);
        transform-origin: left center;
      }

      &-prev .swiper-pagination-bullet-progressive_inner {
        transform: scale(1)!important;
      }
      &-next .swiper-pagination-bullet-progressive_inner {
        transform: scale(0)!important;
      }
    }
    &.stopped >>> .swiper-pagination-bullet-progressive-active .swiper-pagination-bullet-progressive_inner {
      transform: scaleX(0);
    }
  }

  // cube
  >>> .swiper-container-cube {
    .swiper-slide {
      opacity: 0;

      &-active,
      &-prev,
      &-next {
        opacity: 1;
      }
    }
  }
}
</style>
