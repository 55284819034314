import { isBoolean, isNumber } from 'lodash';

export const coverflowEffectDefault = {
  slideShadows: true,
  rotate: 50,
  stretch: 0,
  depth: 100,
  modifier: 1,
};

export function coverflowEffectValidator(value) {
  if (value.slideShadows && !isBoolean(value.slideShadows)) return false;
  if (value.rotate && !isNumber(value.rotate)) return false;
  if (value.stretch && !isNumber(value.stretch)) return false;
  if (value.depth && !isNumber(value.depth)) return false;
  if (value.modifier && !isNumber(value.modifier)) return false;
  return true;
}
