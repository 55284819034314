import { isNumber } from 'lodash';
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Parallax,
  Lazy,
  EffectFade,
  EffectCoverflow,
  EffectFlip,
  EffectCube,
  Zoom,
  Keyboard,
  Mousewheel,
  Virtual,
  HashNavigation,
  History,
  Controller,
  A11y,
  Thumbs,
} from 'swiper';
export const BaseSwiperProps = {
  // general
  init: {
    type: Boolean,
    default: true,
  },
  updateOnWindowResize: {
    type: Boolean,
    default: true,
  },
  initialSlide: {
    type: Number,
    default: 0,
  },
  direction: {
    type: String,
    default: 'horizontal',
    validator: value => ['horizontal', 'vertical'].includes(value),
  },
  speed: {
    type: Number,
    default: 300,
  },
  setWrapperSize: {
    type: Boolean,
    default: false,
  },
  virtualTranslate: {
    type: Boolean,
    default: false,
  },
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  autoHeight: {
    type: Boolean,
    default: false,
  },
  roundLengths: {
    type: Boolean,
    default: false,
  },
  nested: {
    type: Boolean,
    default: false,
  },
  uniqueNavElements: {
    type: Boolean,
    default: true,
  },
  effect: {
    type: String,
    default: 'slide',
    validator: value => ['slide', 'fade', 'cube', 'coverflow', 'flip'].includes(value),
  },
  runCallbacksOnInit: {
    type: Boolean,
    default: true,
  },
  watchOverflow: {
    type: Boolean,
    default: false,
  },
  userAgent: {
    type: String,
  },
  url: {
    type: String,
  },
  on: {
    type: Object,
    default: () => ({}),
  },
  // CSS Scroll Snap
  cssMode: {
    type: Boolean,
    default: false,
  },
  // Slides grid
  spaceBetween: {
    type: Number,
    default: 0,
  },
  slidesPerView: {
    type: [Number, String],
    default: 1,
    validator: value => isNumber(value) || value === 'auto',
  },
  slidesPerColumn: {
    type: Number,
    default: 1,
  },
  slidesPerColumnFill: {
    type: String,
    default: 'column',
    validator: value => ['column', 'row'].includes(value),
  },
  slidesPerGroup: {
    type: Number,
    default: 1,
  },
  slidesPerGroupSkip: {
    type: Number,
    default: 0,
  },
  centeredSlides: {
    type: Boolean,
    default: false,
  },
  centeredSlidesBounds: {
    type: Boolean,
    default: false,
  },
  slidesOffsetBefore: {
    type: Number,
    default: 0,
  },
  slidesOffsetAfter: {
    type: Number,
    default: 0,
  },
  normalizeSlideIndex: {
    type: Boolean,
    default: true,
  },
  centerInsufficientSlides: {
    type: Boolean,
    default: false,
  },
  // Grab Cursor
  grabCursor: {
    type: Boolean,
    default: false,
  },
  // Touches
  touchEventsTarget: {
    type: String,
    default: 'wrapper',
    validator: value => ['container', 'wrapper'].includes(value),
  },
  touchRatio: {
    type: Number,
    default: 1,
  },
  touchAngle: {
    type: Number,
    default: 45,
  },
  simulateTouch: {
    type: Boolean,
    default: true,
  },
  shortSwipes: {
    type: Boolean,
    default: true,
  },
  longSwipes: {
    type: Boolean,
    default: true,
  },
  longSwipesRatio: {
    type: Number,
    default: 0.5,
  },
  longSwipesMs: {
    type: Number,
    default: 300,
  },
  followFinger: {
    type: Boolean,
    default: true,
  },
  allowTouchMove: {
    type: Boolean,
    default: true,
  },
  threshold: {
    type: Number,
    default: 0,
  },
  touchStartPreventDefault: {
    type: Boolean,
    default: true,
  },
  touchStartForcePreventDefault: {
    type: Boolean,
    default: false,
  },
  touchMoveStopPropagation: {
    type: Boolean,
    default: false,
  },
  iOSEdgeSwipeDetection: {
    type: Boolean,
    default: false,
  },
  iOSEdgeSwipeThreshold: {
    type: Number,
    default: 20,
  },
  touchReleaseOnEdges: {
    type: Boolean,
    default: false,
  },
  passiveListeners: {
    type: Boolean,
    default: true,
  },
  // Touch Resistance
  resistance: {
    type: Boolean,
    default: true,
  },
  resistanceRatio: {
    type: Number,
    default: 0.85,
  },
  // Swiping / No swiping
  preventInteractionOnTransition: {
    type: Boolean,
    default: false,
  },
  allowSlidePrev: {
    type: Boolean,
    default: true,
  },
  allowSlideNext: {
    type: Boolean,
    default: true,
  },
  noSwiping: {
    type: Boolean,
    default: false,
  },
  noSwipingClass: {
    type: String,
    default: 'swiper-no-swiping',
  },
  noSwipingSelector: {
    type: String,
  },
  swipeHandler: {
    type: [String, HTMLElement],
    default: null,
  },
  // Clicks
  preventClicks: {
    type: Boolean,
    default: true,
  },
  preventClicksPropagation: {
    type: Boolean,
    default: true,
  },
  slideToClickedSlide: {
    type: Boolean,
    default: false,
  },
  // Freemode
  freeMode: {
    type: Boolean,
    default: false,
  },
  freeModeMomentum: {
    type: Boolean,
    default: true,
  },
  freeModeMomentumRatio: {
    type: Number,
    default: 1,
  },
  freeModeMomentumVelocityRatio: {
    type: Number,
    default: 1,
  },
  freeModeMomentumBounce: {
    type: Boolean,
    default: false,
  },
  freeModeMomentumBounceRatio: {
    type: Number,
    default: 1,
  },
  freeModeMinimumVelocity: {
    type: Number,
    default: 0.02,
  },
  freeModeSticky: {
    type: Boolean,
    efault: false,
  },
  // Progress
  watchSlidesProgress: {
    type: Boolean,
    default: false,
  },
  watchSlidesVisibility: {
    type: Boolean,
    default: false,
  },
  // Images
  preloadImages: {
    type: Boolean,
    default: true,
  },
  updateOnImagesReady: {
    type: Boolean,
    default: true,
  },
  // Loop
  loop: {
    type: Boolean,
    default: false,
  },
  loopAdditionalSlides: {
    type: Number,
    default: 0,
  },
  loopedSlides: {
    type: Number,
    default: null,
  },
  loopFillGroupWithBlank: {
    typ: Boolean,
    default: false,
  },
  loopPreventsSlide: {
    type: Boolean,
    default: true,
  },
  // Breakpoints
  breakpoints: {
    type: Object,
    default: null,
  },
  // Observer
  observer: {
    type: Boolean,
    default: false,
  },
  observeParents: {
    type: Boolean,
    default: false,
  },
  observeSlideChildren: {
    type: Boolean,
    default: false,
  },
  // Namespace
  containerModifierClass: {
    type: String,
    default: 'swiper-container-',
  },
  slideClass: {
    type: String,
    default: 'swiper-slide',
  },
  slideActiveClass: {
    type: String,
    default: 'swiper-slide-active',
  },
  slideDuplicateActiveClass: {
    type: String,
    default: 'swiper-slide-duplicate-active',
  },
  slideVisibleClass: {
    type: String,
    default: 'swiper-slide-visible',
  },
  slideDuplicateClass: {
    type: String,
    default: 'swiper-slide-duplicate',
  },
  slideNextClass: {
    type: String,
    default: 'swiper-slide-next',
  },
  slideDuplicateNextClass: {
    type: String,
    default: 'swiper-slide-duplicate-next',
  },
  slidePrevClass: {
    type: String,
    default: 'swiper-slide-prev',
  },
  slideDuplicatePrevClass: {
    type: String,
    default: 'swiper-slide-duplicate-prev',
  },
  slideBlankClass: {
    type: String,
    default: 'swiper-slide-invisible-blank',
  },
  wrapperClass: {
    type: String,
    default: 'swiper-wrapper',
  },
};

export const SwiperComponentsList = {
  navigation: Navigation,
  pagination: Pagination,
  scrollbar: Scrollbar,
  autoplay: Autoplay,
  parallax: Parallax,
  lazy: Lazy,
  fadeEffect: EffectFade,
  coverflowEffect: EffectCoverflow,
  flipEffect: EffectFlip,
  cubeEffect: EffectCube,
  thumbs: Thumbs,
  zoom: Zoom,
  keyboard: Keyboard,
  mousewheel: Mousewheel,
  virtual: Virtual,
  hashNavigation: HashNavigation,
  history: History,
  controller: Controller,
  a11y: A11y,
};
