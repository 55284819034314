import { isString, isNumber, isBoolean } from 'lodash';

export const zoomDefault = {
  maxRatio: 3,
  minRatio: 1,
  toggle: true,
  containerClass: 'swiper-zoom-container',
  zoomedSlideClass: 'swiper-slide-zoomed',
};

export function zoomValidator(value) {
  if (value.maxRatio && !isNumber(value.maxRatio)) return false;
  if (value.minRatio && !isNumber(value.minRatio)) return false;
  if (value.toggle && !isBoolean(value.toggle)) return false;
  if (value.containerClass && !isString(value.containerClass)) return false;
  if (value.zoomedSlideClass && !isString(value.zoomedSlideClass)) return false;
  return true;
}
