var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:`swiper-${_vm.id}`,staticClass:"base-swiper",class:_vm.classes,attrs:{"id":`swiper-${_vm.id}`}},[_c('div',{ref:"container",staticClass:"base-swiper__container swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.list),function(item,i){return _c('div',{key:i,staticClass:"swiper-slide",style:({
          width: _vm.isAutoSlidesPerView
            ? `${_vm.autoSlideWidth}px`
            : _vm.instance
            ? `${_vm.instance.slidesSizesGrid[i]}px`
            : null,
        })},[_vm._t("item",null,{"item":item}),(_vm.lazy)?_c('div',{staticClass:"swiper-lazy-preloader base-swiper__loader"}):_vm._e()],2)}),0),(_vm.hasPaginationAtAll)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasPagination),expression:"hasPagination"}],ref:"pagination",staticClass:"base-swiper__pagination swiper-pagination"}):_vm._e(),(_vm.hasScrollbarAtAll)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasScrollbar),expression:"hasScrollbar"}],ref:"scrollbar",staticClass:"swiper-scrollbar"}):_vm._e()]),(_vm.hasNavigationAtAll)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasNavigation),expression:"hasNavigation"}],staticClass:"base-swiper__navigation swiper-navigation"},[_c('div',{ref:"prev",staticClass:"base-swiper__prev swiper-button-prev"},[_vm._t("prev",function(){return [_c('base-button',{attrs:{"theme":"plain","color":_vm.color,"icon":_vm.prevIcon}})]})],2),_c('div',{ref:"next",staticClass:"base-swiper__next swiper-button-next"},[_vm._t("next",function(){return [_c('base-button',{attrs:{"theme":"plain","color":_vm.color,"icon":_vm.nextIcon}})]})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }