import { isBoolean } from 'lodash';

export const fadeEffectDefault = {
  crossFade: false,
};

export function fadeEffectValidator(value) {
  if (value.crossFade && !isBoolean(value.crossFade)) return false;
  return true;
}
