import { isString, isBoolen, isNumber } from 'lodash';
import Swiper from 'swiper';
export const thumbsDefault = {
  slideThumbActiveClass: 'swiper-slide-thumb-active',
  thumbsContainerClass: 'swiper-container-thumbs',
  multipleActiveThumbs: true,
  autoScrollOffset: 0,
};

export function thumbsValidator(value) {
  if (!value.swiper || !(value.swiper instanceof Object || value.swiper instanceof Swiper))
    return false;
  if (value.slideThumbActiveClass && !isString(value.slideThumbActiveClass)) return false;
  if (value.thumbsContainerClass && !isString(value.thumbsContainerClass)) return false;
  if (value.multipleActiveThumbs && !isBoolen(value.multipleActiveThumbs)) return true;
  if (value.autoScrollOffset && !isNumber(value.autoScrollOffset)) return false;
  return true;
}
