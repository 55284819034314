import { isBoolean } from 'lodash';

export const hashNavigationDefault = {
  watchState: false,
  replaceState: false,
};

export function hashNavigationValidator(value) {
  if (value.watchState && !isBoolean(value.watchState)) return false;
  if (value.replaceState && !isBoolean(value.replaceState)) return false;
  return true;
}
