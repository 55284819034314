import { isArray, isBoolean, isString } from 'lodash';
import { Swiper } from 'swiper';

export const controllerDefault = {
  control: undefined,
  inverse: false,
  by: 'slide',
};

export function controllerValidator(value) {
  if (
    !value.control ||
    !isArray(value.control) ||
    !value.control.every(item => item instanceof Swiper)
  )
    return false;
  if (value.inverse && !isBoolean(value.inverse)) return false;
  if (value.by && !isString(value.by)) return false;
  return true;
}
