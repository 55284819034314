import { isBoolean, isNumber, isString } from 'lodash';

export const mousewheelDefault = {
  forceToAxis: false,
  releaseOnEdges: false,
  invert: false,
  sensitivity: 1,
  eventsTarget: 'container',
};

export function mousewheelValidator(value) {
  if (value.forceToAxis && !isBoolean(value.forceToAxis)) return false;
  if (value.releaseOnEdges && !isBoolean(value.releaseOnEdges)) return false;
  if (value.invert && !isBoolean(value.invert)) return false;
  if (value.sensitivity && !isNumber(value.sensitivity)) return false;
  if (
    value.eventsTarget &&
    !(isString(value.eventsTarget) || value.eventsTarget instanceof HTMLElement)
  )
    return false;
  return true;
}
