<template>
  <div class="pricelist-block-grid" :class="`l-flex-${grid}-gutter-${gutter}`">
    <div v-for="(item, i) in list" :key="i" class="l-col">
      <slot name="item" :item="item" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricelistBlockGrid',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    grid: {
      type: String,
      default: '4_ml-6',
    },
    gutter: {
      type: [String, Number],
      default: 20,
    },
  },
};
</script>
