import { isBoolean } from 'lodash';

export const flipEffectDefault = {
  slideShadows: true,
  limitRotation: true,
};

export function flipEffectValidator(value) {
  if (value.slideShadows && !isBoolean(value.slideShadows)) return false;
  if (value.limitRotation && !isBoolean(value.limitRotation)) return false;
  return true;
}
