<template>
  <div class="pricelist-block">
    <base-swiper
      v-if="isMobile"
      :list="list"
      slides-per-view="auto"
      :auto-slide-width="165"
      :space-between="24"
      :slides-offset-before="32"
      :slides-offset-after="32"
      class="pricelist-block__swiper"
    >
      <template #item="{ item }">
        <slot name="item" :item="item" />
      </template>
    </base-swiper>
    <pricelist-block-grid
      v-else
      :list="list"
      :grid="grid"
      :gutter="gutter"
      class="pricelist-block__grid"
    >
      <template #item="{ item }">
        <slot name="item" :item="item" />
      </template>
    </pricelist-block-grid>
  </div>
</template>

<script>
import BaseSwiper from '@/components/BaseSwiper/BaseSwiper';
import PricelistBlockGrid from './PricelistBlockGrid';
import breakpoint from '@/utils/breakpoints';
export default {
  name: 'PricelistBlock',
  components: { BaseSwiper, PricelistBlockGrid },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    grid: {
      type: String,
      default: '4_ml-6',
    },
    gutter: {
      type: [String, Number],
      default: 20,
    },
  },
  computed: {
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    isMobile() {
      return this.screenWidth < breakpoint.sm;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.pricelist-block {

  &__swiper >>> .swiper-container {
    padding: 2rem 0;
    margin: -2rem;
  }
}
</style>
