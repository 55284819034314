import { isBoolean, isNumber } from 'lodash';

export const cubeEffectDefault = {
  slideShadows: true,
  shadow: true,
  shadowOffset: 20,
  shadowScale: 0.94,
};

export function cubeEffectValidator(value) {
  if (value.slideShadows && !isBoolean(value.slideShadows)) return false;
  if (value.shadow && !isBoolean(value.shadow)) return false;
  if (value.shadowOffset && !isNumber(value.shadowOffset)) return false;
  if (value.shadowScale && !isNumber(value.shadowScale)) return false;
  return true;
}
