import { isBoolean, isString } from 'lodash';

export const historyDefault = {
  replaceState: true,
  key: 'slides',
};

export function historyValidator(value) {
  if (value.replaceState && !isBoolean(value.replaceState)) return false;
  if (value.key && !isString(value.key)) return false;
  return true;
}
