import { isNumber, isBoolean } from 'lodash';

export const autoplayDefault = {
  delay: 3000,
  stopOnLastSlide: true,
  disableOnInteraction: false,
  reverseDirection: false,
  waitForTransition: true,
};

export function autoplayValidator(value) {
  if (value.delay && !isNumber(value.delay)) return false;
  if (value.stopOnLastSlide && !isBoolean(value.stopOnLastSlide)) return false;
  if (value.disableOnInteraction && !isBoolean(value.disableOnInteraction)) return false;
  if (value.reverseDirection && !isBoolean(value.reverseDirection)) return false;
  if (value.waitForTransition && !isBoolean(value.waitForTransition)) return false;
  return true;
}
